import React from "react"
import { graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import required modules
import SwiperCore, { Pagination, Navigation } from "swiper"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import { GatsbyImage } from "gatsby-plugin-image"
import { Autoplay } from "swiper"

const Success_Story_Slider = props => {
  return (
    <>
      <div className="">
        <>
          <Fade>
            <Swiper
              // direction={"vertical"}
              // autoplay={{
              //   delay: 3000,
              //   disableOnInteraction: false,
              // }}
              navigation={{
                nextEl: ".swiper-button-next-square",
                prevEl: ".swiper-button-prev-square",
              }}
              slidesPerView={1}
              speed={1000}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
              spaceBetween={0}
              loop={true}
              modules={[Autoplay]}
            >
              {props.story_array.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="bg-dusk">
                      <div className="h-80 sm:h-80 md:h-header lg:h-screen ">
                        <BackgroundImage {...item.image} className="h-full" />
                      </div>
                      <div
                        className={
                          props.custom_height ? "bg-dusk h-screen" : "bg-dusk "
                        }
                      >
                        <div className="container mx-auto">
                          <Fade bottom cascade>
                            <div className="sm:py-20 py-10">
                              <div className="text-4xl text-mint">
                                {item.title}
                              </div>
                              <div
                                className={
                                  props.no_title
                                    ? "text-2xl text-mint"
                                    : "text-2xl text-mint mb-6"
                                }
                              >
                                {item.sub_title}
                              </div>
                              <div className="flex lg:flex-row flex-col text-white sm:gap-20 gap-6  bg-dusk lg:text-2xl md:text-base text-sm">
                                <div className=" text-white max-w-2xl ">
                                  {item.description}
                                  <a
                                    href={item.link1}
                                    target="_blank"
                                    className="hover:text-mint duration-200"
                                  >
                                    {item.link1_name}
                                  </a>
                                </div>
                                <div className=" text-mint max-w-2xl">
                                  {item.quote}
                                  <a
                                    href={item.link2}
                                    target="_blank"
                                    className="hover:text-white/50 duration-200"
                                  >
                                    {item.link2_name}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Fade>
        </>
      </div>
    </>
  )
}

export default Success_Story_Slider
