// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../components/common/main-slider"
import Area_Slider from "../components/common/areas-of-play-slider"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

import textile_white from "../images/icons/textile_white.svg"
import intergrate_white from "../images/icons/intergrate_white.svg"
import advance_white from "../images/icons/advance_white.svg"

import textile_mint from "../images/icons/textile_mint.svg"
import intergrate_mint from "../images/icons/intergrate_mint.svg"
import advance_mint from "../images/icons/advance_mint.svg"

//import key icons

import health_before_icon from "../images/applications/key-icons/Consumer_health_before_click.svg"
import health_after_icon from "../images/applications/key-icons/Consumer_health_once_clicked.svg"
import tech_before_icon from "../images/applications/key-icons/Consumer_tech_before_clicked.svg"

import tech_after_icon from "../images/applications/key-icons/Consumer_tech_once clicked.svg"
import outdoor_before_icon from "../images/applications/key-icons/Outdoor_before_clicked.svg"
import outdoor_after_icon from "../images/applications/key-icons/Outdoor_after_clicked.svg"
import tech_blue_before_icon from "../images/applications/key-icons/Consumer_tech_blue_before.svg"
import tech_blue_after_icon from "../images/applications/key-icons/Consumer_tech_blue_after.svg"
import Success_Story_Slider from "../components/common/success-story-slider"

const Applications = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
    salmon_texture: convertToBgImage(getImage(data.salmon_texture)),
    led: convertToBgImage(getImage(data.led)),
    grid1: convertToBgImage(getImage(data.grid1)),
    grid2: convertToBgImage(getImage(data.grid2)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    slider_1: convertToBgImage(getImage(data.slider_1)),
    slider_2: convertToBgImage(getImage(data.slider_2)),
    slider_3: convertToBgImage(getImage(data.slider_3)),
    dream: convertToBgImage(getImage(data.dream)),

    logo_symbol: getImage(data.logo_symbol),

    //application connected clothing images

    cloth_activewear_img: convertToBgImage(getImage(data.app_cloth_1)),
    cloth_remote_img: convertToBgImage(getImage(data.app_cloth_2)),
    cloth_workwear_img: convertToBgImage(getImage(data.app_cloth_3)),

    //application connected devices image

    device_computer_img: convertToBgImage(getImage(data.app_device_1)),
    device_health_img: convertToBgImage(getImage(data.app_device_2)),
    device_smart_img: convertToBgImage(getImage(data.app_device_3)),

    // application wearable images

    wear_arvr_img: convertToBgImage(getImage(data.app_wearable_1)),
    wear_smartpatch_img: convertToBgImage(getImage(data.app_wearable_2)),
    wear_wrist_img: convertToBgImage(getImage(data.app_wearable_3)),

    //consumer technology images

    audio_img: convertToBgImage(getImage(data.con_tech1)),
    con_tech_img: convertToBgImage(getImage(data.con_tech2)),
    interactive_img: convertToBgImage(getImage(data.con_tech3)),
    smart_accesory_img: convertToBgImage(getImage(data.con_tech4)),

    // health and wellnesses images

    frequency_img: convertToBgImage(getImage(data.health1)),
    health_wellness_img: convertToBgImage(getImage(data.health2)),
    pain_img: convertToBgImage(getImage(data.health3)),
    remote_img: convertToBgImage(getImage(data.health4)),
    respirating_img: convertToBgImage(getImage(data.health5)),

    // outdoor and perform images

    con_cloths_img: convertToBgImage(getImage(data.outdoor1)),
    outdoor_img: convertToBgImage(getImage(data.outdoor2)),
    recovery_img: convertToBgImage(getImage(data.outdoor3)),
    safety_img: convertToBgImage(getImage(data.outdoor4)),

    //success story consumer health images

    carin: convertToBgImage(getImage(data.success_health1)),
    dreem: convertToBgImage(getImage(data.success_health2)),

    //suceess outdoor images

    nadix_img: convertToBgImage(getImage(data.success_outdoor1)),
    napapirinji_img: convertToBgImage(getImage(data.success_outdoor2)),
    nivia_img: convertToBgImage(getImage(data.success_outdoor3)),
    odlo_img: convertToBgImage(getImage(data.success_outdoor4)),

    // success consumer tech

    suc_con_tec_img: convertToBgImage(getImage(data.suc_con_tec_img)),

    //banner image

    banner_img: convertToBgImage(getImage(data.banner_img)),
  }

  const [activePlatform, setActivePlatform] = useState(0)
  const [activeSS, setActiveSS] = useState(0)
  const [active, setActive] = useState(0)
  console.log(active)

  const successStories = [
    {
      icon: health_before_icon,
      icon_active: health_after_icon,
      icon_title: "Consumer Health",
      hero_image: images.dream,
      hero_title: "Dream",
      hero_sub_title:
        " Smart sleep headband that measures and helps improve sleep",
      hero_description: " ",
    },
    {
      icon: tech_blue_before_icon,
      icon_active: tech_blue_after_icon,
      icon_title: "Consumer Technology",
    },
    {
      icon: outdoor_before_icon,
      icon_active: outdoor_after_icon,
      icon_title: "Outdoor and Workwear",
    },
  ]

  const story1 = [
    {
      image: images.suc_con_tec_img,
      title: "",
      sub_title: "",
      description:
        " We’d love to show you some more of our products, but due to the NDAs we signed, we are unable to. We’ll have to leave it to your imagination!",
      quote: "",
    },
  ]
  const story2 = [
    {
      image: images.odlo_img,
      title: "ODLO I-THERMIC",
      sub_title: " Smart heating base layer with temperature sensing",
      description:
        " Combining Clim8’s advanced intelligent thermal technology and MAS Holdings' Twinery innovation, ODLO’s I-THERMIC uses the most advanced active heating textile technology available to give wearers full control of their personal microclimate via the integrated app. The ODLO I-THERMIC base and midlayers are powered by an attached waterproof battery, providing four hours of continuous heating, or a full day of microclimate management, reducing the need for extra layers and avoiding wet, cold, sweaty clothes caused by overdressing during winter.",
      quote:
        '"Effective temperature control is important in colder weather, but also others kinds of outdoor activities and conditions. Whether you ski, climb or hike, nobody wants to look bulky, overheat, freeze or constantly remove layers. I-THERMIC gives you the freedom and lightness. With ODLO I-THERMIC your ski day will not be ruined-Timo Pape, ODLO Brand Director"',
    },
    {
      image: images.napapirinji_img,
      title: "NAPAPIJIRI - VF",
      sub_title: " Rainwear jacket with an illuminated logo",
      description:
        "Integrating a light array into a raincoat, was a challenge that we were eager to undertake. By integrating our lighting technology onto the sleeve of a raincoat for the Napapijiri brand under the VF Corporation, we were able to create an eye-catching and functional piece of clothing that illuminated their brand logo and created excitement around the brand. This stellar lighting system integration allowed the brand to engage with its customers while offering an aesthetic spin through lighting. This technology is powered by a rechargeable lithium-ion battery module and can provide power up to 8 hours on a single charge.",
      quote: "",
    },
    {
      image: images.nivia_img,
      title: "NIVEA",
      sub_title: " Sweat sensing t-shirt",
      description:
        "In cooperation with the Beiersdorf team we developed a first of its kind t-shirt that was able to measure the amount of sweat armpits produced to better understand individuals sweat patterns and the effect of NIVEA antiperspirant on perspiration . In addition to measuring sweat the shirt also has the ability to record the wearer's movements and the ambient temperature to better identify causes of increased sweating. This shirt was created specifically to fullfil an R&D application, allowing Beiersdorf to gather valuable insights for product development from user trials. The technology integration involved a collection of embroidered conductive threads in the underarm area of the T-shirt along with sensing points in these threads are able to determine changes in conductivity of the textile as a function of the amount of perspiration.",
      quote:
        "“What was interesting about this project was the use of the latest technologies to improve our products and the ability of MAS to quickly make changes to the developed T-shirt prototypes and to help shape the development process in a goal-oriented manner. The sweat-measuring T-shirt is an advanced solution that constantly supports NIVEA to learn more about the science behind human sweating under real-life conditions and to continuously incorporate this knowledge into the development of new and better antiperspirant products.” -Beiersdorf Team",
    },
    {
      image: images.nadix_img,
      title: "Nadi X",
      sub_title: " Technology to guide the practice of yoga",
      description:
        "Nadi X yoga experience provides guidance on the body as well as audio feedback, like a personal instructor. Founded by Wearable X, the smart yoga pants and app guide the wearer with gentle vibration to draw attention to the areas of the body the wearer needs to focus. Softmatter by MAS as investors in Wearable X worked with the team to integrate 10 haptic modules and 5 accelerometers into a wash and dry stretch garment. These sensors allow the garment to track both position and movement - metrics that are communicated to the user's phone via integrated Bluetooth. The corresponding software analyses the wearer's position and indicates at the end of each pose sequence how successful the wearer's posture is in each pose. ",
      quote: "",
      // "Effective temperature control is important in colder weather, but also others kinds of outdoor activities and conditions. Whether you ski, climb or hike, nobody wants to look bulky, overheat, freeze or constantly remove layers. I-THERMIC gives you the freedom and lightness. With ODLO I-THERMIC your ski day will not be ruined-Timo Pape, ODLO Brand Director",
    },
  ]
  const story3 = [
    {
      image: images.carin,
      title: "LIFESENSE",
      sub_title: " Hello Carin, Goodbye Leaks",
      description:
        " Stress urinary incontinence in women is a common problem. Women often experience urine leaks during sports or while sneezing, laughing or jumping. Carin’s patented smart underwear pairs with a wearable sensor and tracking app to create a tailored exercise program designed to eliminate leaks in a matter of weeks. Carin’s three-part combination of smart textiles, Bluetooth sensor, and a tailored exercise program provides the key. Carin is a perfect fit to achieve pelvic floor strength in any busy lifestyle. You can learn more about Carin ",
      quote:
        '"The Softmatter team helped LifeSense provide a high quality, comfortable and stylish product for women, using the latest production technologies. "-',
      link1: "https://www.carinwear.com/",
      link2: "https://www.lifesense-group.com/",
      link1_name: "here!",
      link2_name: " Lifesense Group",
    },
    // {
    //   image: images.dreem,
    //   title: "Dreem",
    //   sub_title: " Smart Sleep Headband",
    //   description:
    //     "Connected headband, worn at night, aimed at improving sleep and monitoring brain activity. .",
    //   quote:
    //     '"What stood out for us is the partnered approach that Softmatter adopted in taking our product from sketch to scale.  This help create a product that was scalable and robust, which delivered on the functional expectations – Dreem Team"',
    //   link1: "",
    //   link2: "",
    //   link1_name: "",
    //   link2_name: " ",
    // },
  ]

  const slider = [
    {
      title: "Wristables",
      icon_title1: "Outdoor and workwear",
      icon_title2: "Health",
      icon_title3: "Consumer Tech ",
      icon2: health_before_icon,
      icon3: tech_before_icon,
      icon1: outdoor_before_icon,
      content:
        "Engineered wrist straps with advanced textiles such as flat knit and woven elastics allow for the creation of entirely new comfortable next-to-skin structures without hindering functionality and long-term use.",
      image: images.wear_wrist_img,
    },
    {
      title: "AR and VR",
      icon_title2: "Health",
      icon_title3: "Consumer Tech",
      icon2: health_before_icon,
      icon3: tech_before_icon,
      content:
        "Possibilities range from the creation of wearables for immersive experiences via motion analysis and feedback, to the utilization of our stretchable fabric-based high-speed data cables to transfer 4K video.",
      image: images.wear_arvr_img,
    },
    {
      title: "Smart patches",
      icon_title1: "Outdoor and workwear ",
      icon_title2: "Health",
      icon_title3: "",
      icon2: health_before_icon,
      icon1: outdoor_before_icon,
      content:
        " More comfortable and sustainable wearable patch sensors, that is thin, soft and next-to skin - opening a new door for remote patient monitoring.",
      image: images.wear_smartpatch_img,
    },
  ]

  const slider2 = [
    {
      title: "Smart Homes ",
      icon_title3: "Consumer Technology",
      icon3: tech_before_icon,
      content:
        "  Replacing hard surfaces with textiles. Aesthetic product designs that complement existing in-home consumer-style choices with design freedom to build complex form factors",
      image: images.device_smart_img,
    },

    {
      title: "Health and wellness Devices",
      icon_title2: "Health",
      icon2: health_before_icon,
      content:
        "We want to put humanity back into healthcare, by developing a range of solutions built for analysis and enhancement of sleep and respiration.",
      image: images.device_health_img,
    },
    {
      title: "Computer Peripherals and accessories",
      icon_title3: "Consumer Technology",
      icon3: tech_before_icon,
      content:
        " The utilization of touch textiles to create interactive fabric surfaces, applicable from keyboards to accessories",
      image: images.device_computer_img,
    },
  ]

  const slider3 = [
    {
      title: "Remote Patient Monitoring",
      icon_title2: "Health",
      icon2: health_before_icon,
      content:
        "Utilizing fabric-integrated sensors and actuators to create next-generation remote patient monitoring solutions.",
      image: images.cloth_remote_img,
    },
    {
      title: "Workwear",
      icon_title1: "Outdoor and workwear",
      icon_title2: "",
      icon_title3: "",
      icon1: outdoor_before_icon,
      icon2: "",
      icon3: "",
      content:
        "Upgrade perception with active lighting to keep you visible and safe in low light conditions, alongside aesthetic differentiation. With motion and biopotential analysis for fitness and posture",
      image: images.cloth_workwear_img,
    },
    {
      title: "Activewear",
      icon_title1: "Outdoor and workwear",
      icon_title2: "",
      icon_title3: "",
      icon1: outdoor_before_icon,
      icon2: "",
      icon3: "",
      content:
        "Leveraging our wide technology collection to build soft goods that allow you to perform at your best, while staying safe including leveraging heating and cooling technology to create a micro-climate to battle external temperatures.",
      image: images.cloth_activewear_img,
    },
  ]

  const areasOfPlayGrid1 = [
    {
      image: images.remote_img,
      title: "Remote Patient Monitoring",
      description:
        "Wearable technology that enables patients to safely spend more time outside of the hospital",
    },
    {
      image: images.pain_img,
      title: "Pain Management",
      description:
        "Non-invasive pain management solutions leveraging thermal regulation and TENS/EMS feedback",
    },
    {
      image: images.respirating_img,
      title: "Respiration and Sleep",
      description:
        "Soft systems with integrated sensors and actuators to analyze and enhance sleep and respiration",
    },
    {
      image: images.frequency_img,
      title: "Frequency Therapy",
      description:
        "Our feedback suite contains three main technologies, Haptics, TENS, and EMS. Our seamlessly integrated haptic system is extremely versatile and has seen use-cases ranging from vibration for posture correction, and tactile feedback to the creation of immersive devices.​",
    },
  ]

  const areasOfPlayGrid2 = [
    {
      image: images.smart_accesory_img,
      title: "Smart Accessories and Devices",
      description:
        "All of our platforms can be programmatically actuated and linked, allowing for the creation of intelligent wearables that respond to external stimuli, or user-designed patterns.",
    },
    {
      image: images.audio_img,
      title: "Audio",
      description:
        "Certified  acoustic fabrics specifically designed for uninterrupted sound passthrough from materials to product",
    },
    {
      image: images.interactive_img,
      title: "Interactive Fabric Interfaces",
      description:
        "The utilization of touch textiles to create interactive fabric surfaces, applicable from keyboards to automobile interiors.",
    },
  ]

  const areasOfPlayGrid3 = [
    {
      image: images.recovery_img,
      title: "Recovery and Wellness",
      description:
        "Leveraging heating and cooling technology to create a microclimate to battle external temperatures.",
    },
    {
      image: images.con_cloths_img,
      title: "Connected Clothing",
      description:
        "Tracking metrics ranging from muscle activation, to respiration, to motion using a variety of sensors, and providing feedback where necessary using haptic actuators.",
    },
    {
      image: images.safety_img,
      title: "Safety in low light conditions",
      description:
        "Upgrade perception with active lighting to keep you visible and safe in low light conditions, alongside aesthetic differentiation.",
    },
  ]

  const areasOfPlay = [
    {
      switch_title: "Consumer Health",
      // sub_title: "PASSIVE TECHNOLOGY",
      title: "Health and Wellness Futures",
      content:
        "Patient metric analysis and pain management via thermal comfort, EMS, TENS and sleep solutions",
      image: health_before_icon,
      image_active: health_after_icon,
      cover: images.health_wellness_img,
    },
    {
      switch_title: "Consumer Technology",
      // sub_title: "INTEGRATED TECHNOLOGY",
      title: "Consumer Technology Futures",
      content:
        "Replacing hard surfaces with textiles, flexible conductors for high-speed data transfer, modular wearables, and immersive experiences.",
      image: tech_before_icon,
      image_active: tech_after_icon,
      cover: images.con_tech_img,
    },
    {
      switch_title: "Outdoor and Workwear",
      // sub_title: "ADVANCE TECHNOLOGY",
      title: "Outdoor and Workwear",
      content:
        "Visibility for safety and aesthetics, motion and biopotential analysis for fitness and posture, thermal regulation for chilly climates, and much more.",
      image: outdoor_before_icon,
      image_active: outdoor_after_icon,
      cover: images.outdoor_img,
    },
  ]

  //   const platform = [
  //     {
  //       title: "Technology Integration",
  //       sub_title: "Technology Embedded",
  //       content: "+ Patented Reaction Injection Moulding (RIM) technology",
  //       content2:
  //         "+ Ability to mold Polyurethane foam, TPU Film, Fabric (Nylon/Polyester/Elastane blends)",
  //       //   content3:
  //       //     "",
  //       image: "../../images/brand/symbol-mint.png",
  //       cover: images.led,
  //     },
  //     {
  //       title: "Finishes",
  //       sub_title: "Technology Embedded",
  //       content:
  //         "Our visibility solution comes in two main integratable form factors:",
  //       content2:
  //         "Light Guides (Strips) – LEDs embedded in optical grade silicone strips, perfect for safety based visibility applications.",
  //       content3:
  //         "Point Source (Dots) – Discrete LEDs embedded directly into fabric which function well for both visibility and aesthetic product differentiation.",
  //       image: "../../images/brand/symbol-mint.png",
  //       cover: images.dusk_texure,
  //     },
  //   ]

  const platformSlider = [
    {
      title: "Wearables",
      image: textile_white,
      image_active: textile_mint,
      title_description:
        "By combining years of expertise with stellar integration techniques, we bring ideas to life by crafting solutions that create new and functional wearables experiences.",
    },
    {
      title: "Connected Devices",
      image: intergrate_white,
      image_active: intergrate_mint,
      title_description:
        "Utilizing fabric-integrated sensors and actuators to create next-generation connected devices",
    },
    {
      title: "Connected Clothing",
      image: advance_white,
      image_active: advance_mint,
      title_description:
        "Tracking metrics ranging from muscle activation, to respiration, to motion using a variety of sensors, and providing feedback where necessary using haptic actuators.",
    },
  ]

  return (
    <Layout>
      <Seo title="Applications" />
      <section>
        <BackgroundImage
          {...images.dusk_texure}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col w-full z-10 sm:mt-0 -mt-52">
                  <div className="sm:text-6xl text-4xl sm:text-left text-center  mb-1 max-w-2xl text-white">
                    Industry <div>focused</div> innovation
                  </div>
                  <div className=" sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Solutions built across a variety of
                    <div> applications</div>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className=" absolute right-0 bottom-0">
                  <StaticImage
                    src="../images/applications/Banner.png"
                    alt="Softmatter"
                    placeholder="blurred"
                    width={820}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-mint mb-4 uppercase text-center ">
                    AHEAD OF THE CURVE
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Purpose-driven design for
                    <div>consumer-centric applications</div>
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. The platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    We collaborate with leading brands in the consumer health,
                    technology, outdoor and
                    <div>
                      workwear sectors to develop wearable, smart textile
                      solutions that unlock value for both
                    </div>
                    our retail partners and end consumers.
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="mb-20">
              <Fade top>
                <div className="text-center text-2xl font-firs-thin text-white mb-4">
                  LEADING THE CURVE
                </div>
              </Fade>
              <Fade bottom>
                <div className="text-center text-5xl lg:text-6xl text-white">
                  Areas of Play
                </div>
              </Fade>
            </div>
            <div>
              <div className="mt-14">
                <div className="flex items-center justify-center gap-0">
                  {areasOfPlay.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className="">
                          <div className="flex flex-col justify-center items-center w-24 sm:w-60">
                            {active == index ? (
                              <>
                                <img
                                  src={item.image_active}
                                  alt="Softmatter"
                                  width={70}
                                  className="mb-8 cursor-pointer sm:w-16 w-10 "
                                  onClick={() => setActive(index)}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.image}
                                  alt="Softmatter"
                                  width={70}
                                  className="mb-8 cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActive(index)}
                                />
                              </>
                            )}
                            <div
                              className={
                                active == index
                                  ? "font-firs-medium cursor-pointer text-mint text-xs sm:text-xl pb-2 text-center w-3/5 sm:w-4/5 border-b-2 border-mint duration-200 h-16"
                                  : "font-firs-medium cursor-pointer text-white text-xs sm:text-xl pb-2 text-center w-3/5 sm:w-4/5 border-b-2 border-transparent duration-200 h-16"
                              }
                              onClick={() => setActive(index)}
                            >
                              {item.switch_title}
                            </div>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
                <Fade bottom>
                  <div className="mt-14">
                    <div className="flex lg:flex-row flex-col items-center sm:text-left text-center">
                      <div className="w-full lg:w-1/2">
                        <BackgroundImage
                          {...areasOfPlay[active].cover}
                          className="bg-chalk py-44 "
                        ></BackgroundImage>
                      </div>
                      <div className="w-full lg:w-3/5 bg-dusk sm:p-14 py-4 ">
                        {/* <div className="text-xl font-light text-mint">
                          {areasOfPlay[active].sub_title}
                        </div> */}
                        <div className="text-4xl font-light text-mint ">
                          {areasOfPlay[active].title}
                        </div>
                        <div className="text-lg font-light mt-2 text-white">
                          {areasOfPlay[active].content}
                        </div>
                        <Link to="/contact">
                          <button className="bg-transparent py-3 px-6 mt-5 hover:bg-mint/50 duration-200 border border-mint hover:text-white  text-mint uppercase">
                            learn more
                          </button>
                        </Link>
                      </div>
                    </div>

                    {active == 0 && (
                      <>
                        <Area_Slider area_array={areasOfPlayGrid1} />
                      </>
                    )}
                    {active == 1 && (
                      <>
                        <Area_Slider area_array={areasOfPlayGrid2} />
                      </>
                    )}
                    {active == 2 && (
                      <>
                        <Area_Slider area_array={areasOfPlayGrid3} />
                      </>
                    )}
                    {/* <div className="grid grid-cols-3 gap-5 mt-5">
                      {active == 0 &&
                        areasOfPlay[0].areasOfPlayGrid.map((item, index) => {
                          return (
                            <div key={index}>
                              <BackgroundImage
                                {...item.image}
                                className="bg-red-400 py-44"
                              ></BackgroundImage>
                              <div className="text-center text-mint text-2xl my-4">
                                {item.title}
                              </div>
                              <div className="text-center text-white text-xl px-5">
                                {item.description}
                              </div>
                            </div>
                          )
                        })}

                      {active == 1 &&
                        areasOfPlay[1].areasOfPlayGrid.map((item, index) => {
                          return (
                            <div key={index}>
                              <BackgroundImage
                                {...item.image}
                                className="bg-red-400 py-44"
                              ></BackgroundImage>
                              <div className="text-center text-mint text-2xl my-4">
                                {item.title}
                              </div>
                              <div className="text-center text-white text-2xl px-5">
                                {item.description}
                              </div>
                            </div>
                          )
                        })}

                      {active == 2 &&
                        areasOfPlay[2].areasOfPlayGrid.map((item, index) => {
                          return (
                            <div key={index}>
                              <BackgroundImage
                                {...item.image}
                                className="bg-red-400 py-44"
                              ></BackgroundImage>
                              <div className="text-center text-mint text-2xl my-4">
                                {item.title}
                              </div>
                              <div className="text-center text-white text-2xl px-5">
                                {item.description}
                              </div>
                            </div>
                          )
                        })}
                    </div> */}
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-salmon py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <Fade bottom cascade>
              <div className=" pb-20">
                <div className="text-center text-6xl mb-4 text-white">
                  Areas of Application
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto text-dusk">
                  Solutions built across a variety of applications to craft a
                  smarter tomorrow for a variety of brands
                </div>
              </div>
            </Fade>
            <div className="flex lg:flex-row flex-col mb-80 lg:mb-52 2xl:mb-64">
              <div className="w-full lg:w-4/12 mb-10 lg:mb-52">
                <div className="">
                  {platformSlider.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className="mb-8">
                          <span onClick={() => setActivePlatform(index)}>
                            <div className="flex items-center">
                              <div
                                className={
                                  activePlatform == index
                                    ? "w-20 h-1 bg-white mr-4 duration-200"
                                    : "w-0 duration-200"
                                }
                              ></div>
                              <div
                                className={
                                  activePlatform == index
                                    ? "text-white text-2xl duration-200 cursor-pointer"
                                    : "text-dusk text-2xl duration-200 cursor-pointer"
                                }
                              >
                                {item.title}
                              </div>
                            </div>
                            {activePlatform == index && (
                              <>
                                <div className="text-xl mt-4 text-white pr-16">
                                  {item.title_description}
                                </div>
                              </>
                            )}
                          </span>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
              <div className="w-full lg:w-7/12 pb-96 mb-20 lg:mb-0 lg:pb-24">
                <div className="text-white absolute w-full">
                  {activePlatform == 0 && (
                    <>
                      <MainSlider slider_array={slider} icon />
                    </>
                  )}
                  {activePlatform == 1 && (
                    <>
                      <MainSlider slider_array={slider2} icon />
                    </>
                  )}
                  {activePlatform == 2 && (
                    <>
                      <MainSlider slider_array={slider3} icon />
                    </>
                  )}

                  <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96  justify-center items-center mt-14">
                    <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="pt-24">
            <Fade bottom cascade>
              <div className="text-dusk pb-20 px-5">
                <div className="text-center text-2xl font-firs-thin  mb-4">
                  BRINGING PRODUCTS TO LIFE
                </div>
                <div className="text-center text-6xl mb-4">Success Stories</div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  We partner with industry leaders to develop a long-time
                  vision. We can anticipate your product needs both today and
                  for the future
                </div>
              </div>
            </Fade>
            <div className="container mx-auto mb-20">
              <div className="">
                <div className="flex items-center justify-center gap-0">
                  {successStories.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div>
                          <div className="flex flex-col justify-center items-center  w-24 sm:w-60">
                            {activeSS == index ? (
                              <>
                                <img
                                  src={item.icon_active}
                                  alt="softmater"
                                  className=" cursor-pointer mb-5  sm:w-16 w-10"
                                  onClick={() => setActiveSS(index)}
                                  placeholder="blurred"
                                  width={70}
                                />
                                {/* <GatsbyImage
                                  placeholder="blurred"
                                  image={item.icon_active}
                                  alt="Softmatter"
                                  className="w-8 cursor-pointer mb-5"
                                  onClick={() => setActiveSS(index)}
                                /> */}
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.icon}
                                  alt="softmater"
                                  className="cursor-pointer mb-5 sm:w-16 w-10"
                                  onClick={() => setActiveSS(index)}
                                  placeholder="blurred"
                                  width={70}
                                />
                                {/* <GatsbyImage
                                  placeholder="blurred"
                                  image={item.icon}
                                  alt="Softmatter"
                                  className="w-8 cursor-pointer mb-5"
                                  onClick={() => setActiveSS(index)}
                                /> */}
                              </>
                            )}
                            <div
                              className={
                                activeSS == index
                                  ? "font-firs-medium cursor-pointer text-mint text-xs sm:text-xl pb-2 text-center w-3/5 sm:w-4/5 border-b-2 border-mint duration-200 h-16"
                                  : "font-firs-medium cursor-pointer text-dusk text-xs sm:text-xl pb-2 text-center w-3/5 sm:w-4/5 border-b-2 border-transparent duration-200 h-16"
                              }
                              onClick={() => setActiveSS(index)}
                            >
                              {item.icon_title}
                            </div>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
            </div>

            <div>
              {/* <Success_Story_Slider successStories={successStories} /> */}
            </div>

            {activeSS == 0 && (
              <>
                <Success_Story_Slider story_array={story3} />
              </>
            )}
            {activeSS == 1 && (
              <>
                <Success_Story_Slider story_array={story1} no_title />
              </>
            )}
            {activeSS == 2 && (
              <>
                <Success_Story_Slider story_array={story2} custom_height />
              </>
            )}
            {/* <div className="">
              <BackgroundImage
                {...successStories[activeSS].hero_image}
                className=" h-screen"
              ></BackgroundImage>
              <div className="bg-dusk">
                <div className="container mx-auto">
                  <Fade bottom cascade>
                    <div className="py-20">
                      <div className="text-4xl text-mint">
                        {successStories[activeSS].hero_title}
                      </div>
                      <div className="text-2xl text-mint mb-6">
                        {successStories[activeSS].hero_sub_title}
                      </div>
                      <div className="text-xl text-white max-w-2xl">
                        {successStories[activeSS].hero_description}
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:w-1/2 w-full">
            <BackgroundImage
              {...images.salmon_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="lg:w-1/2 w-full">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-5">
                <div className="text-center text-5xl sm:text-6xl mb-4 px-1">
                  Contact Us
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Let's get started.
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-dusk  hover:bg-salmon duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ApplicationsImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    salmon_texture: file(relativePath: { eq: "textures/salmon_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    health: file(relativePath: { eq: "applications/health.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    dream: file(relativePath: { eq: "applications/dream.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app_cloth_1: file(
      relativePath: {
        eq: "applications/Applications-Images/Connected-Clothing/Activewear.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app_cloth_2: file(
      relativePath: {
        eq: "applications/Applications-Images/Connected-Clothing/Remote_Patient_monitoring.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app_cloth_3: file(
      relativePath: {
        eq: "applications/Applications-Images/Connected-Clothing/Workwear.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app_device_1: file(
      relativePath: {
        eq: "applications/Applications-Images/Connected-Devices/Computer_Peripherals.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app_device_2: file(
      relativePath: {
        eq: "applications/Applications-Images/Connected-Devices/Health_Wellness.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app_device_3: file(
      relativePath: {
        eq: "applications/Applications-Images/Connected-Devices/Smart_Homes.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app_wearable_1: file(
      relativePath: {
        eq: "applications/Applications-Images/Wearables/ARVR.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app_wearable_2: file(
      relativePath: {
        eq: "applications/Applications-Images/Wearables/Smart_Patches.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app_wearable_3: file(
      relativePath: {
        eq: "applications/Applications-Images/Wearables/Wrsitables.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    con_tech1: file(
      relativePath: { eq: "applications/Consumer-Technology-Futures/Audio.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    con_tech2: file(
      relativePath: {
        eq: "applications/Consumer-Technology-Futures/Consumer_tech.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    con_tech3: file(
      relativePath: {
        eq: "applications/Consumer-Technology-Futures/INteractive_Fabric_surfaces.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    con_tech4: file(
      relativePath: {
        eq: "applications/Consumer-Technology-Futures/smart_accesories.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    health1: file(
      relativePath: {
        eq: "applications/Health-and-Wellness-Futures/Frequency_Therapy.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    health2: file(
      relativePath: {
        eq: "applications/Health-and-Wellness-Futures/Health_and_wellness_Futures.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    health3: file(
      relativePath: {
        eq: "applications/Health-and-Wellness-Futures/Pain_Management.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    health4: file(
      relativePath: {
        eq: "applications/Health-and-Wellness-Futures/Remote_Monitoring.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    health5: file(
      relativePath: {
        eq: "applications/Health-and-Wellness-Futures/Respiration.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    outdoor1: file(
      relativePath: {
        eq: "applications/Outdoor-and-Performance/Connected_Clothing.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    outdoor2: file(
      relativePath: { eq: "applications/Outdoor-and-Performance/Outdoor.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    outdoor3: file(
      relativePath: { eq: "applications/Outdoor-and-Performance/Recovery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    outdoor4: file(
      relativePath: { eq: "applications/Outdoor-and-Performance/Safety.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    success_health1: file(
      relativePath: {
        eq: "applications/Success-Stories/Consumer-Health/Carin_Lifesense.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    success_health2: file(
      relativePath: {
        eq: "applications/Success-Stories/Consumer-Health/Dreem.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    success_tech: file(
      relativePath: {
        eq: "applications/Success-Stories/Consumer-Tech/Consumer_tech.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    success_outdoor1: file(
      relativePath: {
        eq: "applications/Success-Stories/Outdoor-and-Workwear/NadiX.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    success_outdoor2: file(
      relativePath: {
        eq: "applications/Success-Stories/Outdoor-and-Workwear/Napapiriji.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    success_outdoor3: file(
      relativePath: {
        eq: "applications/Success-Stories/Outdoor-and-Workwear/Nivea.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    success_outdoor4: file(
      relativePath: {
        eq: "applications/Success-Stories/Outdoor-and-Workwear/Odlo.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    suc_con_tec_img: file(
      relativePath: {
        eq: "applications/Success-Stories/Consumer-Tech/Consumer_tech.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    banner_img: file(relativePath: { eq: "applications/Banner.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default Applications
