import React from "react"
import Fade from "react-reveal/Fade"
// import Swiper core and required modules

import { Swiper, SwiperSlide } from "swiper/react"
import BackgroundImage from "gatsby-background-image"
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

// install Swiper modules
SwiperCore.use([Navigation])
const Area_Slider = props => {
  return (
    <>
      <div className="">
        <>
          <Fade bottom cascade>
            <Swiper
              // direction={"vertical"}
              // autoplay={{
              //   delay: 3000,
              //   disableOnInteraction: false,
              // }}
              navigation={{
                nextEl: ".swiper-button-next-square",
                prevEl: ".swiper-button-prev-square",
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
              slidesPerView={3}
              speed={1000}
              spaceBetween={15}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
            >
              {props.area_array.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="mt-5 ">
                      <BackgroundImage
                        {...item.image}
                        className=" py-40 "
                      ></BackgroundImage>
                      <div className="text-center text-mint text-2xl my-4">
                        {item.title}
                      </div>
                      <div className="text-center text-white text-lg px-5">
                        {item.description}
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Fade>
        </>
      </div>
    </>
  )
}

export default Area_Slider
